jQuery(document).ready(function ($) {



    jQuery(document).ready(function ($) {
        var svg = '<a class="menu-raccourcis__menu--toggle"><svg class="icon icon-tabler icon-tabler-arrow-narrow-right" width="25" height="25" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="5" y1="12" x2="19" y2="12" /><line x1="15" y1="16" x2="19" y2="12" /><line x1="15" y1="8" x2="19" y2="12" /></svg></a>';
        var defaultImgSrc = 'assets/soins-du-corps.jpg'; // Chemin par défaut de l'image
        jQuery('.menu-raccourcis__menu--menu .raccourcis>div').hide();
        jQuery('.menu-raccourcis__menu--menu .raccourcis:first-child>div').show();
        jQuery('.menu-raccourcis__menu--menu .raccourcis:first-child').addClass('active');
        jQuery('.menu-raccourcis__menu--menu li.raccourcis').append(svg);
        jQuery('.menu-raccourcis__menu--menu li:not(.active) > a').on('click', function (event) {
            jQuery(this).next().next().trigger('click');
            event.preventDefault();
        });
        jQuery('.menu-raccourcis__menu--toggle').on('click', function (event) {
            let container = jQuery(this).parent('li');
            let imgSrc = container.find('a').attr('data-img-src') || defaultImgSrc; 
            if (!container.hasClass('active')) {
                jQuery('.menu-raccourcis__menu--menu .raccourcis').removeClass('active');
                jQuery('.menu-raccourcis__menu--menu .raccourcis>div').slideUp();
                container.addClass('active');
            } else {
                container.toggleClass('active');
            }
            jQuery(this).prev('div').slideToggle();
    
            jQuery('.menu-raccourcis__img img').attr('src', imgSrc);
            event.preventDefault();
        });
     
        jQuery('.menu-raccourcis__img img').attr('src', jQuery('.menu-raccourcis__menu--menu .raccourcis.active a').attr('data-img-src') || defaultImgSrc);
    });
    



})
