import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';


$('.prestations__lateral .home-news__items').slick({
    dots: false,
    arrows: false,
    autoplay:true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
});