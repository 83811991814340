import $ from 'jquery';

global.$ = $;
global.jQuery = $;

import './foundation';
import './app';
import './site';
import './simple-parallax-js';
import './accordeon';
import './raccourcis-home';
import './sliders';