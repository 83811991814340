import simpleParallax from 'simple-parallax-js';

const images = document.querySelectorAll('*[data-parallax]');
new simpleParallax(images, {
    scale: 1.2,
    delay: .9,
    transition: 'cubic-bezier(0,0,0,1)',
});

var image = document.getElementsByClassName('para-overflow');
new simpleParallax(image, {
    scale: 1.1,
    delay: 1.3,
    transition: 'cubic-bezier(0,0,0,0)',
	overflow: true
});


