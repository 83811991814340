
function searchPopup() {
    var $searchPopup = jQuery('.js-trigger-search');
    var $searchClose = jQuery('.js-close-search');
    var $searchModal = jQuery('.search-modal');
    $searchPopup.on('click', function(evt) {
        evt.preventDefault();
        $searchModal.toggleClass('search-modal__modal--opened');
        setTimeout(function() {
            jQuery('form input.js-search-input').focus();
        }, 100)
    });
    // Fermer la recherche

    $searchClose.on('click', function(evt) {
        $searchModal.removeClass('search-modal__modal--opened');
    });
}



jQuery(document).ready(function($) {
    searchPopup();
    jQuery('.btn-open').on('click', function(e){
        jQuery(this).toggleClass('close');
        jQuery('.off-canvas').toggleClass('show');
    });
    jQuery('.bt_top').on('click', function (event) {
        jQuery('body, html').stop().animate({scrollTop: 0}, 800);
        event.preventDefault();
    });

    jQuery('.footer__main--address .venir').on('click', function(event) {
        jQuery('.footer-map').slideToggle();
    });
});

