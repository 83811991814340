import $ from "jquery";
import { Foundation } from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);

import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader';
Foundation.onImagesLoaded = onImagesLoaded;

import { Abide } from 'foundation-sites/js/foundation.abide';
Foundation.plugin(Abide, 'Abide');

import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
Foundation.plugin(DropdownMenu, 'DropdownMenu');

import { Drilldown } from 'foundation-sites/js/foundation.drilldown';
Foundation.plugin(Drilldown, 'Drilldown')

import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal')

import { Accordion } from 'foundation-sites/js/foundation.accordion';
Foundation.plugin(Accordion, 'Accordion');

import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu';
Foundation.plugin(AccordionMenu, 'AccordionMenu');

import { Sticky } from 'foundation-sites/js/foundation.sticky';
Foundation.plugin(Sticky, 'Sticky');

import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas';
Foundation.plugin(OffCanvas, 'OffCanvas');

import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
Foundation.plugin(SmoothScroll, 'SmoothScroll');

// import { Tabs } from 'foundation-sites/js/foundation.tabs';
// Foundation.plugin(Tabs, 'Tabs');

document.addEventListener("DOMContentLoaded", function() {
    $(document).foundation();
});
