 // Prérequis pour la mise en place d'Accordeon (balise H2 H3 H4 suivie d'un conteneur "Citations" [balise "blockquote"])
 // les title et content doivent avoir une marge à zero pour eviter que l'accordeon sursaute
 jQuery(function () {
    var i = 1;
    jQuery('.rte h2, .rte h3, .rte h4').each(function() {
        if (jQuery(this).next().is('blockquote')) {
            jQuery(this).addClass('accordion-title innerAccordeon' + i);
            i++;
        }
    });
    if (i === 1) return;
    i = 1;
    jQuery('.rte blockquote').each(function() {
        jQuery(this).addClass('accordion-content innerAccordeon' + i);
        jQuery(".innerAccordeon" + i).wrapAll("<div class='accordion-item'></div>");
        i++;
    });
    var allPanels = jQuery('.accordion-content').hide();
    jQuery('.accordion-title').on('click', function() {
        if (!jQuery(this).parents('.accordion-item').hasClass('is-active')) {
            allPanels.slideUp();
            jQuery('.accordion-item.is-active').removeClass('is-active');
            jQuery(this).next().slideDown();
            jQuery(this).parents('.accordion-item').addClass('is-active');

        } else {
            jQuery('.accordion-item.is-active').removeClass('is-active');
            jQuery(this).next().slideUp();
            jQuery(this).parents('.accordion-item').removeClass('is-active');
        }
        return false;
    });
});
